<template>
  <BaseBlock
    :block="block"
    v-on="$listeners"
    :editorMode="editorMode"
  >
    <template v-slot:menu>
      <SubMenu :activatorText="$t('blocks.headerLevel')">
        <v-list-item
          v-for="(levelItem, index) in levels"
          :key="index"
          @click="setHeaderLevel(levelItem)"
        >
          <div class="d-flex align-center">
            <v-icon
              :size="16"
              class="mr-3 control flex-end"
              :class="{ shown: level === levelItem }"
            >mdi-check</v-icon>
            <div :class="HEADER_LEVELS[levelItem].class">{{HEADER_LEVELS[levelItem].name}}</div>
          </div>
        </v-list-item>
      </SubMenu>
    </template>
    <div
      ref="contenteditable"
      data-testid="header-block"
      :contenteditable="contenteditable"
      class="header-text-field"
      :class="headerClass"
      :placeholder="$t('blocks.emptyHeader')"
      @keydown.enter.prevent="addBlockAfter"
      @blur="updateBlock"
    >{{ text }}</div>
  </BaseBlock>
</template>

<script>
import BaseBlock from '@/components/block/BaseBlock.vue'
import HeaderBlock from '@/store/models/blocks/HeaderBlock'
import SubMenu from '@/components/SubMenu.vue'
import { HEADER_LEVELS } from '@/store/models/blocks/HeaderBlock'

export default {
  props: {
    block: {
      type: HeaderBlock
    },
    editorMode: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      HEADER_LEVELS
    }
  },
  computed: {
    contenteditable() {
      return this.editorMode ? 'plaintext-only' : 'false'
    },
    headerClass() {
      if (this.level == null) {
        return HEADER_LEVELS.h3.class
      }
      return HEADER_LEVELS[this.level]?.class ?? HEADER_LEVELS[3].class
    },
    text: {
      get() {
        return this.block.text
      },
      set(newVal) {
        this.block.text = newVal
      }
    },
    levels() {
      return Object.keys(HEADER_LEVELS)
    },
    level: {
      get() {
        return this.block.level
      },
      set(newVal) {
        this.block.level = newVal
      }
    },
  },
  watch: {
    text: {
      immediate: true,
      async handler(newVal) {
        await this.$nextTick()
        this.$refs.contenteditable.textContent = newVal
      }
    }
  },
  methods: {
    setHeaderLevel(level) {
      this.level = level
      this.$emit('updateBlock')
    },
    updateBlock() {
      this.text = this.$refs.contenteditable.textContent
      this.$emit('updateBlock')
    },
    focus() {
      const el = this.$refs.contenteditable
      const selection = window.getSelection()
      const range = document.createRange()
      selection.removeAllRanges()
      range.selectNodeContents(el)
      range.collapse(false)
      selection.addRange(range)
      el.focus()
    },
    addBlockAfter() {
      this.$emit('addBlockAfter')
    },
  },
  components: {
    BaseBlock,
    SubMenu,
  }
}
</script>

<style
  lang="scss"
  scoped
>
::v-deep .header-text-field input {
  max-height: unset
}

.header-text-field {
  outline: unset;
  width: 100%;
  height: fit-content;
}

.control {
  opacity: 0;
  transition: opacity 0.3s;
}

.shown {
  opacity: 1;
}

[contenteditable=plaintext-only]:empty:not(:focus):before{
  content: attr(placeholder);
  pointer-events: none;
  color: lightgray;
  display: block; /* For Firefox */
}

.header-block-h1 {
  font-size: 2.25em;
  font-weight: 600;
  line-height: 1.2;
}

.header-block-h2 {
  font-size: 1.625em;
  font-weight: 600;
  line-height: 1.2;
}

.header-block-h3 {
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.2;
}
</style>